<template>
  <div>
    <div class="tw-relative tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">
      <div class="sm:tw-rounded-lg sm:tw-overflow-hidden">
        <img alt="" class="tw-h-full tw-w-full tw-object-cover" src="@/assets/img/landing/about/1.jpg">
      </div>
    </div>

    <section class="tw-bg-red-100 tw-mt-12 sm:tw-mt-24 tw-text-left tw-py-8 sm:tw-py-16">
      <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16">

        <div class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8 ">
          <div class="tw-col-span-6">
            <h3 class="tw-font-semibold tw-text-2rem tw-text-black tw-leading-relaxed">About Us</h3>
            <div class="tw-my-6 tw-text-black">

              <div class="tw-block tw-my-4">
                <span class="tw-font-serif tw-text-red">Mango & Lola</span>
                is a full-service online wedding planning and booking company that helps
                couples easily plan their wedding by booking vendors online from a single source with the
                click of a button. The wedding planning and booking process is complicated and very time
                consuming so we created a better way for couples to plan their wedding online while
                saving them time and money.
              </div>
              <div class="tw-block tw-my-4">
                The way we achieve this is by providing the opportunity for vendors to present their
                availability and pricing directly on our site so couples can select the vendors they want
                based on their wedding date, location, budget, ratings and then immediately book and
                manage everything in one place.
              </div>
              <div class="tw-block tw-my-4">
                There is a real need in the wedding industry for our solution as more couples are shopping
                online to create their <span class="tw-font-semibold">Wedding Vendor Dream Team!</span> <span class="tw-font-serif tw-text-red">Mango & Lola</span> provides real-time
                information and greater transparency to make the wedding planning and booking process
                much faster, and far more efficient for both vendors and couples.
              </div>
              <div class="tw-block tw-my-4">
                Simply put, we are your <span class="tw-font-bold">ONE STOP ONLINE SHOP</span> for all your wedding needs.
              </div>
            </div>
           </div>
        </div>

        <div class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8 ">
          <div class="tw-col-span-6">
            <h3 class="tw-font-semibold tw-text-2rem tw-text-black tw-leading-relaxed">Mission Statement</h3>
            <div class="tw-my-6 tw-text-black">

              <div class="tw-block tw-my-4">
                Our goal is to revolutionize the wedding industry through the power of innovation and
                technology by providing greater transparency for wedding vendor availability and pricing
                that helps couples hire the best vendors available in a faster, and much more efficient way.
              </div>
              <div class="tw-block tw-my-4">
                We utilize advanced technology that allows us to exceed expectations of wedding couples
                and vendors by creating a platform that results in better communication and relationships
                among everyone involved in the wedding planning and booking process.
              </div>
              <div class="tw-block tw-my-4">
                We attract and engage wedding vendors from around the world by bringing them to a
                single, central platform where they can grow their business while creating a more
                convenient and transparent customer experience through our leading-edge wedding
                planning and booking marketplace.
              </div>
              <div class="tw-block tw-my-4">
                The greatest joy in life is to fall in LOVE. The wedding day symbolizes the beginning of a
                lifetime together and a beautiful future filled with love and happiness. Mango &amp; Lola is
                honored to be here to make the wedding planning and booking process an easier one for
                everyone planning such a special occasion.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-text-left tw-text-black tw-mt-20 tw-pb-4">
      <h3 class="tw-font-semibold tw-text-2rem tw-leading-relaxed tw-mb-4">Features</h3>
      <div class="tw-grid lg:tw-grid-cols-2 tw-gap-x-6 tw-gap-y-7 sm:tw-auto-rows-fr">
        <div class="tw-col-1 tw-flex tw-flex-col tw-p-12 tw-bg-red-100 tw-rounded-md tw-justify-start tw-items-center">
          <span class="tw-inline-flex tw-items-center tw-p-1 tw-h-16 tw-w-16 tw-justify-center tw-rounded-full tw-bg-white">
            <wifi-icon />
          </span>
          <h5 class="tw-mt-8 tw-mb-4 tw-leading-relaxed tw-text-lg tw-font-semibold">Full Service</h5>
          <ul class="tw-text-center tw-text-sm tw-list-disc tw-list-inside tw-pl-0 tw-leading-relaxed">
            <li>
              Comprehensive wedding platform that is easy to navigate and use
            </li>
            <li>
              Couples have the tools they need to review and book vendors online
            </li>
          </ul>
        </div>
        <div class="tw-col-1 tw-flex tw-flex-col tw-p-12 tw-bg-red-100 tw-rounded-md tw-justify-start tw-items-center">
          <span class="tw-inline-flex tw-items-center tw-p-1 tw-h-16 tw-w-16 tw-justify-center tw-rounded-full tw-bg-white">
            <search-icon />
          </span>
          <h5 class="tw-mt-8 tw-mb-4 tw-leading-relaxed tw-text-lg tw-font-semibold">Transparency</h5>
          <ul class="tw-text-center tw-text-sm tw-list-disc tw-list-inside tw-pl-0 tw-leading-relaxed">
            <li>Vendors provide their current pricing and available dates up front</li>
            <li>Couples have the option to book directly on the site or ask questions</li>
          </ul>
        </div>
        <div class="tw-col-1 tw-flex tw-flex-col tw-p-12 tw-bg-red-100 tw-rounded-md tw-justify-start tw-items-center">
          <span class="tw-inline-flex tw-items-center tw-p-1 tw-h-16 tw-w-16 tw-justify-center tw-rounded-full tw-bg-white">
            <lock-icon />
          </span>
          <h5 class="tw-mt-8 tw-mb-4 tw-leading-relaxed tw-text-lg tw-font-semibold">Secure Booking</h5>
          <ul class="tw-text-center tw-text-sm tw-list-disc tw-list-inside tw-pl-0 tw-leading-relaxed">
            <li>Payments are encrypted and wedding party information is stored online</li>
            <li>Hiring a vendor is easy with immediate results to avoid double booking</li>
          </ul>
        </div>
        <div class="tw-col-1 tw-flex tw-flex-col tw-p-12 tw-bg-red-100 tw-rounded-md tw-justify-start tw-items-center">
          <span class="tw-inline-flex tw-items-center tw-p-1 tw-h-16 tw-w-16 tw-justify-center tw-rounded-full tw-bg-white">
            <award-icon />
          </span>
          <h5 class="tw-mt-8 tw-mb-4 tw-leading-relaxed tw-text-lg tw-font-semibold">Vendor Platform</h5>
          <ul class="tw-text-center tw-text-sm tw-list-disc tw-list-inside tw-pl-0 tw-leading-relaxed">
            <li>Vendors showcase their best work with photos & client reviews</li>
            <li>Rates and availability are managed online through a simple process</li>
            <li>Reservations are made immediately online and stay up to date</li>
            <li>Simple process for issuing invoices and receiving payments</li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import { WifiIcon, SearchIcon, LockIcon, AwardIcon } from "vue-feather-icons";
import i18n from "@/i18n";

export default {
  components: {
    WifiIcon,
    SearchIcon,
    LockIcon,
    AwardIcon,
  },
  metaInfo() {
    return {
      title: i18n.t('navbar.about'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.welcome')
        }
      ],
    }
  },
}

</script>
